import React, { useState, useEffect } from "react";
import dashify from "dashify";
import { options, answers, images } from "./options";

const buttonStyles = {
  width: "100%",
  maxWidth: "13rem",
};

const textStyles = {
  fontSize: "1.2rem",
  fontWeight: "bold",
};

const Nav = ({ step, setStep, answers, setAnswers }) => {
  const optionsLength = Object.keys(options).length;

  /**
   * @param {boolean} answer
   * @returns {*}
   */
  const handleNext = (answer) => {
    const newAnswers = Object.assign({}, answers, {
      [Object.keys(answers)[step]]: answer,
    });
    console.log("new answers", newAnswers);
    setAnswers(newAnswers);
    return step < optionsLength - 1 ? setStep(step + 1) : setStep(step);
  };

  const handleBack = () => {
    const newAnswers = Object.assign({}, answers, {
      [Object.keys(answers)[step]]: false,
    });
    setAnswers(newAnswers);
    return step > 0 ? setStep(step - 1) : setStep(step);
  };

  return (
    <>
      <div style={{ marginBottom: "1rem", textAlign: "center" }}>
        <button
          className="button button--secondary"
          style={buttonStyles}
          onClick={() => handleNext(true)}
        >
          Yes
        </button>
        <button
          className="button button--primary"
          style={{ ...buttonStyles, marginLeft: "1rem" }}
          onClick={() => handleNext(false)}
        >
          No
        </button>
      </div>
      <div style={{ textAlign: "center" }}>
        <button className="button" onClick={handleBack}>
          {"< Back"}
        </button>
      </div>
    </>
  );
};

function Form() {
  const [step, setStep] = useState(0);
  const [answers_, setAnswers] = useState(answers);
  const option = Object.keys(options)[step];

  useEffect(() => {
    console.log("answers", answers_);
  });

  return (
    <>
      {option !== "form" ? (
        <div key={dashify(option)} style={{ textAlign: "center" }}>
          <h2 style={textStyles}>{options[option]}</h2>

          <img style={{ marginBottom: "2rem" }} alt="" src={images[option]} />

          <Nav
            setStep={setStep}
            step={step}
            answers={answers_}
            setAnswers={setAnswers}
          />
        </div>
      ) : (
        <div>
          <p style={textStyles}>
            Please enter your email to get your results and recommended
            products!
          </p>
          <form>
            <div className="form__input-row">
              <div className="form__input-wrapper form__input-wrapper--labelled">
                <input
                  type="first-name"
                  id="first-name"
                  name="first-name"
                  className="form__field form__field--text "
                />
                <label htmlFor="first-name" className="form__floating-label">
                  First Name:
                </label>
              </div>
            </div>

            <div className="form__input-row">
              <div className="form__input-wrapper form__input-wrapper--labelled">
                <input
                  type="last-name"
                  id="last-name"
                  name="last-name"
                  className="form__field form__field--text "
                />
                <label htmlFor="last-name" className="form__floating-label">
                  Last Name:
                </label>
              </div>
            </div>

            <div className="form__input-row">
              <div className="form__input-wrapper form__input-wrapper--labelled">
                <input
                  type="email"
                  id="email"
                  name="email"
                  className="form__field form__field--text "
                />
                <label htmlFor="email" className="form__floating-label">
                  Email:
                </label>
              </div>
            </div>

            <div className="form__input-row">
              <button className="button button--secondary" style={buttonStyles}>
                Submit
              </button>
            </div>
          </form>
        </div>
      )}
    </>
  );
}

export { Form };
