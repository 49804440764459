const options = {
  snore:
    "Do you snore loudly (louder than talking or loud enough to be heard through closed doors)?",
  tired:
    "Do you often feel tired, fatigued, or sleepy during the daytime, even after a “good” night’s sleep?",
  breathing:
    "Has anyone has ever observed you stop breathing during your sleep?",
  blood: "Do you have or are you being treated for high blood pressure?",
  neck: "Does your neck measure more than 15¾ inches (40 cm) around?",
  form: true,
};

const answers = {
  snore: false,
  tired: false,
  breathing: false,
  blood: false,
  neck: false,
};

const images = {
  snore:
    "https://cdn.shopify.com/s/files/1/0254/1794/1055/files/HeartStrong_SurveyGraphic1.png?v=1600107493",
  tired:
    "https://cdn.shopify.com/s/files/1/0254/1794/1055/files/HeartStrong_SurveyGraphic2.png?v=1600107493",
  breathing:
    "https://cdn.shopify.com/s/files/1/0254/1794/1055/files/HeartStrong_SurveyGraphic3.png?v=1600107493",
  blood:
    "https://cdn.shopify.com/s/files/1/0254/1794/1055/files/HeartStrong_SurveyGraphic4.png?v=1600107493",
  neck:
    "https://cdn.shopify.com/s/files/1/0254/1794/1055/files/HeartStrong_SurveyGraphic5.png?v=1600107492",
};

export { options, answers, images };
